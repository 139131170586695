import React, { useEffect, useState } from 'react';

import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEyeSlash, faLink, faLock, faSearch, faShareAlt } from '@fortawesome/free-solid-svg-icons';

import LogIn from './LogIn';

// const secretKey = process.env.REACT_APP_SECRET_KEY; 

function LinkPageDouble({ vendorEntity, emrs, token, onSuccess, onClose, baseURL, mode }) {

  const [step, setStep] = useState(1); // Always start at step 1
  const [vendorEmail, setVendorEmail] = useState(null);
  const [vendorPassword, setVendorPassword] = useState(null);
  const [credentialsReady, setCredentialsReady] = useState(false);
  const [emrEmail, setEmrEmail] = useState(null);
  const [emrPassword, setEmrPassword] = useState(null);
  const [emrOtp, setEmrOtp] = useState('');
  const [selectedEmr, setSelectedEmr] = useState(emrs[0]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredEmrs = emrs.filter(emr => emr.emrName.toLowerCase().includes(searchQuery.toLowerCase()));

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const imgUrls = ["https://linkassets.blob.core.windows.net/cobalt/cobalt-logo.png", vendorEntity.logoUrl];
    const imgPromises = imgUrls.map(imgUrl => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgUrl;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imgPromises)
      .then(() => setImagesLoaded(true))
      .catch(err => console.log("Failed to load images", err));
  }, [vendorEntity.logoUrl]);

  useEffect(() => {
    if (credentialsReady) {
      handleSubmit();
      setCredentialsReady(false); // Reset the flag
    }
  }, [credentialsReady, emrEmail, emrPassword]); // Depend on the flag and the credentials

  if (!imagesLoaded) {
    return null; // or a loading spinner
  }

  // Function to handle the transition from step 1 based on mode
  const handleContinueFromStep1 = () => {
    switch (mode) {
      case 'dual_credentials_required':
        setStep(2);
        break;
      case 'credentials_required':
        setStep(6);
        break;
      case 'mfa_required':
        setStep(9);
        break;
      case 'new':
      default:
        setStep(2); // Proceed to the next logical step or customize as needed
        break;
    }
  };

  const handleErrorResponse = (error) => {
    if (error.response && error.response.data.error === 'invalid credentials') {
      setError("The credentials you provided are not valid. Please go back and try again.");
      setStep(8);
    } else if (error.response && error.response.data.error === 'invalid otp') {
      setError("The code you provided is not valid. Please go back and try again.");
      setStep(10);
    } else if (error.response && error.response.data.error === 'missing otp') {
      setError("Please enter the OTP sent to your email.");
      setStep(9); // New step for OTP input
    } else {
      setError("An unexpected error occurred. Please try again.");
      setStep(8);
    }
  };

  const handleCredentialsSubmit = (email, password) => {
    setEmrEmail(email);
    setEmrPassword(password);
    setCredentialsReady(true); // Set the flag to trigger useEffect
  };

  const handleSubmit = async () => {
    setStep(7);

    // Give time to show the loading screen
    // await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      var axiosInstance = axios.create({ baseURL: baseURL });

      // Decrypt credentials right before use
      // const bytesEmail = CryptoJS.AES.decrypt(vendorEmail, secretKey);
      // const originalVendorEmail = bytesEmail.toString(CryptoJS.enc.Utf8);

      // const bytesPassword = CryptoJS.AES.decrypt(vendorPassword, secretKey);
      // const originalVendorPassword = bytesPassword.toString(CryptoJS.enc.Utf8);

      const response = await axiosInstance.post(`/link/sync/submit`, 
        { 
          emr_email: emrEmail, 
          emr_password: emrPassword,
          emr_otp: emrOtp,
          emr_id: selectedEmr.id,
          // vendorEmail: originalVendorEmail,
          // vendorPassword: originalVendorPassword,
          vendor_email: vendorEmail,
          vendor_password: vendorPassword,
          token: token,
        }, 
        { 
          withCredentials: true 
        },
      );
      console.log("server response sucess: ", response);

      if (response.data.success) {
        onSuccess && onSuccess(response.data.token)

        setStep(11);
        setVendorEmail(null);
        setVendorPassword(null);
        setEmrEmail(null);
        setEmrPassword(null);

        // If onSuccess is null that means the Link component is running in a separate window so
        // we need to redirect to the vendor callback so they get the token.
        if (!onSuccess) {
          // Give time to show the loading screen
          await new Promise(resolve => setTimeout(resolve, 4000));

          const callbackUrl = new URL(vendorEntity.callbackUrl);
          callbackUrl.searchParams.append('token', response.data.token);
          window.location.href = callbackUrl.href;
        }
      }
    } catch (error) {
      console.error("server response error: ", error);
      handleErrorResponse(error); // Handle different errors
    }

  };

  const handleVendorCheck = async (email, password) => {
    setStep(3);

    // Give time to show the loading screen
    await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      var axiosInstance = axios.create({ baseURL: baseURL });
      const response = await axiosInstance.post(`/link/sync/authenticate`, 
        { 
          email, 
          password,
        }, 
        { 
          withCredentials: true 
        },
      );
      console.log("server response sucess: ", response);

      if (response.data.success) {
        setStep(6)
        // Encrypt credentials
        // const encryptedEmail = CryptoJS.AES.encrypt(email, secretKey).toString();
        // const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();

        // Store encrypted credentials
        // setVendorEmail(encryptedEmail);
        // setVendorPassword(encryptedPassword);
        setVendorEmail(email);
        setVendorPassword(password);
      }
    } catch (error) {
      console.error("server response error: ", error);
      setError("The credentials you provided are not valid. Please go back and try again.");
      setStep(4);
    }
  };

  return (
    <div className="cobalt-flex cobalt-items-center cobalt-justify-center cobalt-min-h-screen cobalt-bg-gray-400 cobalt-bg-opacity-90">
      <div className="cobalt-pl-7 cobalt-pr-7 cobalt-pt-4 cobalt-pb-8 cobalt-w-360 cobalt-h-[85vh] cobalt-bg-white cobalt-rounded cobalt-shadow-lg cobalt-flex cobalt-flex-col cobalt-rounded-lg">
        {step === 1 && (
          <>
            <div className="cobalt-flex cobalt-justify-center cobalt-space-x-4 cobalt-mb-8 cobalt-mt-8 cobalt-items-center">
              <img src={vendorEntity.logoUrl} alt={`${vendorEntity.name} Logo`} className="cobalt-h-12 cobalt-mb-5" />
              <FontAwesomeIcon icon={faShareAlt} className="cobalt-self-center cobalt-text-gray-500" />
              <img src="https://linkassets.blob.core.windows.net/cobalt/cobalt-logo.png" alt="Cobalt Logo" className="cobalt-h-7 cobalt-mb-2" />
            </div>
            <h1 className="cobalt-embedded-h1 cobalt-mx-auto cobalt-mb-12 cobalt-text-center cobalt-font-light">{vendorEntity.name} uses <span className="cobalt-font-semibold">Cobalt</span> to connect your data</h1>
            <div className="cobalt-w-10/12 cobalt-mx-auto">
              <h2 className="cobalt-embedded-h2 cobalt-mb-1">
                <FontAwesomeIcon icon={faLock} className="cobalt-mr-1" /> Secure
              </h2>
              <p className="cobalt-embedded-p cobalt-mb-4 cobalt-ml-6">Your data is handled at industry-leading standards.</p>
              <h2 className="cobalt-embedded-h2 cobalt-mb-1">
                <FontAwesomeIcon icon={faEyeSlash} className="cobalt-mr-1" /> Private
              </h2>
              <p className="cobalt-embedded-p cobalt-mb-4 cobalt-ml-6">Your data will never be shared without your permission.</p>
            </div>
            <div className="cobalt-mt-auto">
              <hr className="cobalt-border-gray-200 cobalt-mb-4" />
              <p className="cobalt-embedded-p cobalt-mb-6 cobalt-text-center cobalt-text-sm cobalt-text-gray-600">By selecting "Continue" you agree to the <a href="https://www.usecobalt.com/end-user-privacy-policy" target="_blank" rel="noopener noreferrer" className="cobalt-font-semibold cobalt-underline cobalt-text-gray-600">Cobalt End User Privacy Policy</a></p>
              <button onClick={handleContinueFromStep1} className="cobalt-p-2 cobalt-w-full cobalt-bg-primary cobalt-text-white cobalt-rounded cobalt-h-12 hover:cobalt-bg-primary-dark cobalt-transition-colors cobalt-duration-200">
                Continue
              </button>
            </div>
          </>
        )}

        {step === 2 && (
          <LogIn
            entity={vendorEntity}
            handleSubmit={handleVendorCheck}
            setStep={setStep}
            prevStep={1}
          />
        )}

        {step === 3 && (
          <>
            <div className="cobalt-flex cobalt-justify-center cobalt-mb-6">
              <img src="https://linkassets.blob.core.windows.net/cobalt/cobalt-logo.png" alt="XYZ Logo" className="cobalt-h-4" />
            </div> 
            <div className="cobalt-mt-4 cobalt-flex cobalt-flex-col cobalt-items-center cobalt-justify-center cobalt-h-full">
              <div className="cobalt-loader cobalt-ease-linear cobalt-rounded-full cobalt-border-4 cobalt-border-t-4 cobalt-border-gray-200 cobalt-h-12 cobalt-w-12"></div>
              <p className="cobalt-embedded-p cobalt-mt-2 cobalt-text-center">Logging in...</p>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <div className="cobalt-flex cobalt-items-left">
              <button onClick={() => setStep(2)} className="cobalt-text-center cobalt-mb-8">
                <FontAwesomeIcon icon={faArrowLeft} className="cobalt-self-center cobalt-text-gray-500" />
              </button>
            </div>
            <div className="cobalt-flex cobalt-flex-col cobalt-items-center cobalt-justify-center cobalt-h-2/3">
            <h1 className="cobalt-embedded-h1 cobalt-w-5/6 cobalt-mb-4">{`Log in to ${vendorEntity.name} failed.`}</h1>
              <div className="cobalt-w-5/6">
                <p className="cobalt-embedded-p">{error}</p>
              </div>
              <br />
              {/* <div className="cobalt-w-5/6">
                <p className="cobalt-embedded-p">If you need assistance please contact bryan@usecobalt.com</p>
              </div> */}
            </div>
          </>
        )}

        {step === 5 && (
          <>
            <div className="cobalt-flex cobalt-items-center cobalt-justify-between cobalt-p-4">
              <button onClick={() => setStep(2)} className="cobalt-text-gray-500">
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <img src="https://linkassets.blob.core.windows.net/cobalt/cobalt-logo.png" alt="Cobalt Logo" className="cobalt-h-6" />
              {/* <button onClick={onClose} className="text-gray-500">
                X
              </button> */}
            </div>
            <h2 className="cobalt-embedded-h2 cobalt-text-left cobalt-text-xl cobalt-font-semibold cobalt-my-4 cobalt-pl-4">Select your EMR</h2>
            <div className="cobalt-w-full cobalt-p-0 cobalt-relative">
              <FontAwesomeIcon icon={faSearch} className="cobalt-text-gray-500 cobalt-absolute cobalt-left-3 cobalt-top-1/2 cobalt-transform cobalt--translate-y-1/2" />
              <input
                type="text"
                placeholder="Search"
                className="cobalt-pl-10 cobalt-p-2 cobalt-border cobalt-rounded cobalt-w-full"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="cobalt-divide-y cobalt-divide-gray-200">
              {filteredEmrs.map((emr) => (
                <button
                  key={emr.emrName}
                  className="cobalt-flex cobalt-items-center cobalt-p-4 cobalt-w-full cobalt-text-left hover:cobalt-cursor-pointer hover:cobalt-bg-gray-100"
                  onClick={() => {
                    setSelectedEmr(emr);
                    setStep(6);
                  }}
                >
                  <div className="cobalt-flex-shrink-0 cobalt-w-12 cobalt-h-6 cobalt-mr-4">
                    <img src={emr.emrIconUrl} alt={`${emr.emrName} Logo`} className="cobalt-h-full cobalt-w-full cobalt-object-contain" />
                  </div>
                  <div>
                    <p className="cobalt-font-semibold">{emr.emrName}</p>
                    <p className="cobalt-text-gray-600 cobalt-text-xs cobalt-font-thin">{emr.emrInstanceDomain}</p>
                  </div>
                </button>
              ))}
            </div>
          </>
        )}

        {step === 6 && (
          <LogIn
            entity={selectedEmr}
            handleSubmit={handleCredentialsSubmit}
            setStep={setStep}
            prevStep={5}
          />
        )}

        {step === 7 && (
          <>
            <div className="cobalt-flex cobalt-justify-center cobalt-mb-6">
              <img src="https://linkassets.blob.core.windows.net/cobalt/cobalt-logo.png" alt="XYZ Logo" className="cobalt-h-4" />
            </div> 
            <div className="cobalt-mt-4 cobalt-flex cobalt-flex-col cobalt-items-center cobalt-justify-center cobalt-h-full">
              <div className="cobalt-loader cobalt-ease-linear cobalt-rounded-full cobalt-border-4 cobalt-border-t-4 cobalt-border-gray-200 cobalt-h-12 cobalt-w-12"></div>
              <p className="cobalt-embedded-p cobalt-mt-2 cobalt-text-center">Logging in...</p>
            </div>
          </>
        )}

        {step === 8 && (
          <>
            <div className="cobalt-flex cobalt-items-left">
              <button onClick={() => setStep(6)} className="cobalt-text-center cobalt-mb-8">
                <FontAwesomeIcon icon={faArrowLeft} className="cobalt-self-center cobalt-text-gray-500" />
              </button>
            </div>
            <div className="cobalt-flex cobalt-flex-col cobalt-items-center cobalt-justify-center cobalt-h-2/3">
              <h1 className="cobalt-embedded-h1 cobalt-w-5/6 cobalt-mb-4">{`Log in to ${selectedEmr.emrName} failed.`}</h1>
              <div className="cobalt-w-5/6">
                <p className="cobalt-embedded-p">{error}</p>
              </div>
              <br />
              {/* <div className="cobalt-w-5/6">
                <p className="cobalt-embedded-p">If you need assistance please contact bryan@usecobalt.com</p>
              </div> */}
            </div>
          </>
        )}

        {step === 9 && (
            <>
            <div className={`cobalt-flex cobalt-flex-col cobalt-items-start cobalt-mt-12 cobalt-mb-4`}>
              <div className={`cobalt-flex cobalt-flex-row cobalt-items-start cobalt-mb-12`}>  
                <div className={`cobalt-flex-shrink-0 cobalt-mr-2 ${selectedEmr.emrName === "OncoEMR" ? "cobalt-h-16" : "cobalt-h-10"}`}>
                  <img src={selectedEmr.logoUrl || selectedEmr.emrLogoUrl} alt={`${selectedEmr.emrName} Logo`} className="cobalt-h-full cobalt-w-full cobalt-object-contain" />
                </div>
              </div>
                <h1 className="cobalt-text-left cobalt-font-normal">{selectedEmr.emrName} MFA required. Enter the code from your authenticator app or email.</h1>
            </div>
              <div className="cobalt-flex cobalt-flex-col cobalt-items-centers">

                <input
                  type="text"
                  placeholder="Enter the code..."
                  value={emrOtp}
                  onChange={(e) => setEmrOtp(e.target.value)}
                  className="cobalt-mb-2 cobalt-p-2 cobalt-border cobalt-rounded cobalt-w-full"

                />
                <button 
                  onClick={handleSubmit} 
                  disabled={!emrOtp}
                  className={`cobalt-p-2 cobalt-w-full cobalt-bg-primary cobalt-text-white cobalt-rounded cobalt-h-12 ${emrOtp ? 'hover:cobalt-bg-primary-dark cobalt-transition-colors cobalt-duration-200' : 'disabled:cobalt-opacity-50'}`}
                >
                  Submit
                </button>
              </div>
            </>
          )}

        {step === 10 && (
          <>
            <div className="cobalt-flex cobalt-items-left">
              <button onClick={() => setStep(9)} className="cobalt-text-center cobalt-mb-8">
                <FontAwesomeIcon icon={faArrowLeft} className="cobalt-self-center cobalt-text-gray-500" />
              </button>
            </div>
            <div className="cobalt-flex cobalt-flex-col cobalt-items-center cobalt-justify-center cobalt-h-2/3">
              <h1 className="cobalt-embedded-h1 cobalt-w-5/6 cobalt-mb-4">{`Log in to ${selectedEmr.emrName} failed.`}</h1>
              <div className="cobalt-w-5/6">
                <p className="cobalt-embedded-p">{error}</p>
              </div>
              <br />
              {/* <div className="cobalt-w-5/6">
                <p className="cobalt-embedded-p">If you need assistance please contact bryan@usecobalt.com</p>
              </div> */}
            </div>
          </>
        )}

        {step === 11 && (
          <>
            <div className="cobalt-flex cobalt-flex-col cobalt-items-center cobalt-justify-center cobalt-h-2/3">
              <div className="cobalt-flex cobalt-justify-center cobalt-space-x-4 cobalt-mb-8 cobalt-mt-8 cobalt-items-center">
                <img src={selectedEmr.emrLogoUrl} alt={`${selectedEmr.emrName} Logo`} className={`${selectedEmr.emrName === "OncoEMR" ? "cobalt-h-12" : "cobalt-h-6"}`} />
                  <FontAwesomeIcon icon={faLink} className="cobalt-self-center cobalt-text-gray-500" />
                <img src={vendorEntity.logoUrl} alt={`${vendorEntity.name} Logo`} className="cobalt-h-12 cobalt-mb-3" />
              </div>
              <h1 className="cobalt-embedded-h1 cobalt-mb-4">Account successfully linked!</h1>
              <div className="cobalt-w-5/6 cobalt-ml-7">
                <p className="cobalt-embedded-p">Your data will now be synchronized between {selectedEmr.emrName} and {vendorEntity.name}</p>
              </div>
              <div className="cobalt-mt-4">
                {onSuccess && (
                  <button onClick={onClose} className="cobalt-p-2 cobalt-bg-blue-500 cobalt-text-white cobalt-rounded hover:cobalt-bg-blue-700 cobalt-transition-colors cobalt-duration-200">
                    Close
                  </button>
                )}
              </div>
              {/* <div className="mt-4">
                <a href="http://localhost:3001?redirect=true" className="text-blue-500 underline">Back to {vendorEntity.name}</a>
              </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );  
}

export default LinkPageDouble;