import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import LinkPageSingle from './components/LinkPageSingle';
import LinkPageDouble from './components/LinkPageDouble';

function App({ token, onSuccess, onClose }) {
  const [componentToRender, setComponentToRender] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      try {
        let baseURL = token.endsWith(process.env.REACT_APP_TOKEN_CHARS) ? process.env.REACT_APP_SANDBOX_BASE_URL : process.env.REACT_APP_API_BASE_URL;
        let axiosInstance = axios.create({ baseURL: baseURL });

        const response = await axiosInstance.get(`/link/initiate`, {
          params: {
            token: token // Send the token as a query parameter to your API
          }
        });

        console.log('response: ', response)
        // Assuming the response contains a field that indicates which component to render
        // Adjust the condition based on your actual API response
        if (response.data.success && response.data.vendor.type === 'single') {
          setComponentToRender(<LinkPageSingle vendorEntity={response.data.vendor} mode={response.data.mode} emrs={response.data.emrs} token={token} onSuccess={onSuccess} onClose={onClose} baseURL={baseURL}/>);
        } else if (response.data.success &&  response.data.vendor.type === 'double') {
          setComponentToRender(<LinkPageDouble vendorEntity={response.data.vendor} mode={response.data.mode} emrs={response.data.emrs} token={token} onSuccess={onSuccess} onClose={onClose} baseURL={baseURL}/>);
        } else {
          setComponentToRender(<p className='embedded-p'>404 Not Found</p>); // Or any other component/message for invalid cases
        }
      } catch (error) {
        console.error('Failed to check token:', error);
        setComponentToRender(<p className='embedded-p'>Error loading page</p>); // Handle error state
      }
    };

    checkToken();
  }, [onClose, onSuccess, token]);

  return (
    <div className="App">
      {componentToRender}
    </div>
  );
}

export default App;