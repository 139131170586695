import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

const getTokenFromURL = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  return token;
};

// Determine if the app is embedded based on the presence of 'index.html' in the URL path
const isEmbedded = window.location.pathname.includes('index.html');
const token = getTokenFromURL();

// const allowedOrigins = ["*", "https://example.com", "https://anotherdomain.com"];

window.addEventListener('message', (event) => {
    // if (!allowedOrigins.includes(event.origin)) {
    //     console.log("Received message from unauthorized origin:", event.origin);
    //     return;
    // }

    const handleSuccess = (publicToken) => {
      window.parent.postMessage({ type: 'success', publicToken }, event.origin);
    };

    const handleClose = () => {
      window.parent.postMessage({ type: 'close' }, event.origin);
    };

    if (event.data.type === 'init') {
        const root = ReactDOM.createRoot(document.body);
        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <App 
                      token={event.data.token} 
                      onSuccess={(publicToken) => handleSuccess(publicToken)}  
                      onClose={handleClose} 
                    />
                </BrowserRouter>
            </React.StrictMode>
        );
    }
});

// Initialize the app based on the context determined by the URL
if (!isEmbedded && token) {
  const root = ReactDOM.createRoot(document.body);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App token={token} />
      </BrowserRouter>
    </React.StrictMode>
  );
}